export default {
  switchTip: "Switch to English",
  switchTip2: "English",
  success: "成功",
  failure: "失败",
  login: {
    title: "欢迎登录",
    username: "用户名",
    usernameRule1: "用户名不能为空",
    usernameRule2: "用户名最长50位",
    password: "密码",
    passwordRule1: "密码不能为空",
    passwordRule2: "密码至少6位",
    passwordRule3: "密码最长50位",
    login: "登录",
    rememberMe: "记住我",
    forgot: "忘记账号或密码?点击此处!"
  },
  navBar: {
    personal: "个人中心",
    logout: "退出登录",
    closeAll: "全部关闭",
    closeOther: "关闭其他"
  },
  button: {
    add: "新增",
    batchDelete: "批量删除",
    search: "搜索",
    save: "保存",
    edit: "编辑",
    delete: "删除",
    key: "密令",
    restore: "恢复",
    cancel: "取消",
    publish: "发表",
    batchOffline: "批量下线",
    offline: "下线",
    view: "查看",
    batchRestore: "批量恢复",
    deepSearch: "深度搜索",
    more: "更多操作",
    upload: "上传",
    copy: "复制",
    pick: "选取文件"
  },
  confirm: {
    tip: "提示",
    yes: "确定",
    no: "取消",
    content1: "确定退出吗?编辑的内容将不会保存!",
    content2: "确定恢复吗?",
    content3: "确定删除吗?",
    content4: "确定彻底删除吗?",
    content5: "是否删除选中项?",
    content6: "是否彻底删除选中项?",
    content7: "是否启用该角色?",
    content8: "是否禁用该角色?",
    content9: "确定下线该用户吗?",
    content10: "是否强制下线选中用户?",
    content11: "是否解锁该用户?",
    content12: "是否锁定该用户?",
    content13: "是否启用该用户?",
    content14: "是否禁用该用户?",
    content15: "是否恢复选中项?",
    content16: "确定移除"
  },
  input: {
    select: "请选择",
    selectUser: "请选择用户",
    selectSource: "请选择来源",
    startDate: "开始日期",
    endDate: "结束日期",
    separator: "至",
    lastWeek: "最近一周",
    lastHalfMonth: "最近半个月",
    lastMonth: "最近一个月",
    selectCategory: "请选择分类",
    selectTag: "请选择标签",
    selectGender: "请选择性别",
    selectRole: "请选择角色",
    selectDisabled: "请选择禁用状态",
    selectLocked: "请选择锁定状态",
    selectModule: "请选择模块",
    selectType: "请选择类型",
    selectExceptionType: "请选择异常类型",
    startTime: "起始时间",
    endTime: "结束时间",
    selectMethod: "请选择登录方式",
    selectPlatform: "请选择登录平台",
    today: "今天",
    yesterday: "昨天"
  },
  table: {
    user: "用户",
    publishDate: "发表日期",
    updateDate: "更新日期",
    operate: "操作",
    showColumn: "显示列",
    refresh: "刷新",
    createDate: "创建日期",
    avatar: "头像",
    ipSource: "ip来源",
    ipAddress: "ip地址",
    createTime: "创建时间",
    updateTime: "更新时间",
    loginMethod: "登录方式",
    nickname: "昵称",
    loginDevice: "登录设备",
    loginPlatform: "登录平台",
    loginTime: "登录时间",
    optModule: "操作模块",
    optType: "操作类型",
    optDesc: "操作描述",
    optUri: "操作路径",
    optMethod: "操作方法",
    requestParam: "请求参数"
  },
  option: {
    published: "已发表",
    drafts: "草稿箱",
    bin: "回收站",
    deleted: "已删除",
    available: "未删除",
    article: "文章",
    friendLink: "友链",
    displayed: "已发送",
    frontend: "前台",
    backend: "后台",
    gender1: "男",
    gender2: "女",
    gender3: "可男可女",
    gender4: "非男非女",
    locked: "已锁定",
    unlocked: "未锁定",
    disabled: "已禁用",
    enabled: "已启用",
    email: "邮箱",
    qq: "QQ",
    wx: "微信",
    phone: "手机",
    insert: "新增",
    update: "更新",
    delete: "删除",
    select: "查询",
    insertOrUpdate: "新增或修改",
    common: "普通异常",
    illegal: "非法请求"
  },
  switch: {
    top: "置顶",
    public: "公开",
    hidden: "隐藏",
    commentable: "可评论",
    disabled: "禁用",
    enabled: "启用",
    hide: "隐藏",
    anonymous: "匿名",
    locked: "锁定",
    assimilate: "同步"
  },
  article: {
    inputTitle: "请输入文章标题",
    saveDraft: "保存草稿",
    publishArticle: "发表文章",
    exitWithoutSave: "不保存退出",
    dialogTitle1: "发表文章",
    category: "分类",
    tag: "标签",
    cover: "封面",
    coverLink: "封面链接",
    tip1: "将文件拖到此处, 或",
    tip2: "点击上传",
    tip3: "支持 jpg/png/gif 文件, 且不超过5MB",
    titleRule1: "文章标题不能为空!",
    contentRule1: "文章内容不能为空!",
    categoryRule1: "文章分类不能为空!",
    coverRule1: "上传的图片只能是jpg, png, gif格式!",
    coverRule2: "上传图片的大小不能超过5MB!",
    autoSaveSuccess: "自动保存成功!",
    autoSaveFailure: "自动保存失败!",
    title: "标题",
    viewCount: "浏览量",
    likeCount: "点赞量",
    dialogTitle2: "密令设置",
    articleTitle: "文章标题",
    accessKey: "访问密令",
    effectiveCount: "有效次数",
    expireTime: "过期时间",
    inputAccessKey: "请输入访问密令",
    selectExpireTime: "请选择过期时间",
    illegalKey: "该密令不合法!"
  },
  category: {
    inputName: "请输入分类名",
    name: "分类名",
    articleCount: "文章数",
    add: "添加分类",
    edit: "修改分类",
    nameRule1: "分类名不能为空!"
  },
  tag: {
    inputName: "请输入标签名",
    name: "标签名",
    add: "添加标签",
    edit: "修改标签",
    nameRule1: "标签名不能为空!"
  },
  comment: {
    from: "评论人",
    to: "回复人",
    content: "评论内容",
    createTime: "评论时间"
  },
  message: {
    inputNickname: "请输入用户昵称",
    from: "留言人",
    content: "评论内容",
    createTime: "留言时间"
  },
  friendLink: {
    inputName: "请输入友链名称",
    logo: "友链图标",
    name: "友链名称",
    url: "友链地址",
    desc: "友链描述",
    user: "所属用户",
    inputDesc: "请输入友链描述",
    inputLogo: "请输入图标链接",
    inputUrl: "请输入友链地址",
    add: "添加友链",
    edit: "修改友链",
    userRule1: "所属用户不能为空!",
    nameRule1: "友链名称不能为空!",
    descRule1: "友链描述不能为空!",
    logoRule1: "友链图标不能为空!",
    urlRule1: "友链地址不能为空!"
  },
  music: {
    inputName: "请输入音乐名称",
    cover: "音乐封面",
    name: "音乐名称",
    url: "音乐链接",
    author: "音乐作者",
    album: "音乐专辑",
    words: "音乐歌词",
    inputAuthor: "请输入音乐作者",
    inputAlbum: "请输入音乐专辑",
    inputUrl: "请输入音乐链接",
    inputCover: "请输入封面链接",
    inputWords:
      "请输入音乐歌词(以下为示例)\n[00:00.00]\n[00:02.70]入戏太深 - 马旭东\n[00:04.15]作词：马旭东\n[00:06.69]作曲：马旭东\n[00:24.50]One Two Three Go!\n[00:27.26]你的笑总是装作很天真\n...\n[03:32.31]谁能懂那些誓言多伤人",
    add: "添加音乐",
    edit: "修改音乐",
    nameRule1: "音乐名称不能为空!",
    urlRule1: "音乐链接不能为空!"
  },
  statistic: {
    viewCount: "阅读量",
    likeCount: "点赞量",
    viewCountRank: "阅读量排行",
    likeCountRank: "点赞量排行",
    viewCountTrend: "阅读量趋势"
  },
  systemConfig: {
    input: "请输入配置名或描述",
    name: "配置名",
    value: "配置值",
    desc: "配置描述",
    inputName: "请输入配置名",
    inputValue: "请输入配置值",
    inputDesc: "请输入配置描述",
    add: "添加配置",
    edit: "修改配置",
    nameRule1: "配置名不能为空!",
    valueRule1: "配置值不能为空!"
  },
  role: {
    inputName: "请输入角色名称",
    name: "角色名称",
    desc: "角色描述",
    weight: "角色权重",
    userCount: "用户数",
    menuPermission: "菜单权限",
    resourcePermission: "资源权限",
    inputDesc: "请输入角色描述",
    edit1: "修改角色",
    edit2: "修改菜单权限",
    edit3: "修改资源权限",
    add: "添加角色",
    nameRule1: "角色名称不能为空!",
    descRule1: "角色描述不能为空!"
  },
  menu: {
    inputName: "请输入菜单名称",
    name: "菜单名称",
    icon: "菜单图标",
    rank: "排序指标",
    path: "菜单路径",
    component: "菜单组件",
    parentMenu: "父菜单",
    selectIcon: "请选择菜单图标",
    inputPath: "请输入菜单路径",
    inputComponent: "请输入菜单组件",
    add1: "添加菜单",
    add2: "添加子菜单",
    edit1: "修改菜单",
    edit2: "修改子菜单",
    nameRule1: "菜单名称不能为空!",
    iconRule1: "菜单图标不能为空!",
    pathRule1: "菜单路径不能为空!",
    componentRule1: "菜单组件不能为空!",
    nameEn: "菜单名称(en)",
    inputNameEn: "请输入菜单名称(en)",
    nameEnRule1: "菜单名称(en不能为空!"
  },
  resource: {
    inputName: "请输入资源名称",
    name: "资源名称",
    uri: "资源路径",
    requestMethod: "请求方式",
    moduleName: "模块名称",
    inputUri: "请输入资源路径",
    add1: "添加模块",
    add2: "添加资源",
    edit1: "修改模块",
    edit2: "修改资源",
    nameRule1: "资源名称不能为空!",
    moduleNameRule1: "模块名称不能为空!",
    uriRule1: "资源路径不能为空!",
    nameEn: "资源名称(en)",
    inputNameEn: "请输入资源名称(en)",
    nameEnRule1: "资源名称(en)不能为空!",
    moduleNameEnRule1: "模块名称(en)不能为空!"
  },
  user: {
    input: "请输入用户名或昵称",
    gender: "性别",
    email: "邮箱",
    intro: "介绍",
    website: "网站",
    username: "用户名",
    usernameRule1: "该用户名已存在!",
    inputNickname: "请输入昵称",
    emailRule1: "该邮箱不合法!",
    emailRule2: "该邮箱已存在!",
    inputIntro: "请输入介绍",
    inputWebsite: "请输入网站",
    inputEmail: "请输入邮箱",
    add: "添加用户",
    edit: "修改用户",
    nicknameRule1: "用户昵称不能为空!"
  },
  auth: {
    inputUsername: "请输入用户名",
    role: "角色",
    lastLoginTime: "上次登录时间",
    password: "密码",
    password2: "确认密码",
    passwordRule1: "前后密码不一致",
    edit: "修改账号"
  },
  exception: {
    summary: "简要信息",
    detail: "详细信息"
  },
  loginLog: {
    system: "登录系统",
    browser: "浏览器"
  },
  operation: {
    responseData: "响应数据"
  },
  multiFile: {
    inputName: "请输入文件名",
    name: "文件名",
    type: "类型",
    size: "大小",
    desc: "描述",
    inputDesc: "请输入文件描述",
    dirName: "目录名称",
    tip1:
      "目前支持的文件类型有{jpg,png,gif,pdf,xlsx,docx,pptx,wav,mp3,mp4,avi}",
    tip2: "其他文件类型请压缩为zip/rar进行上传",
    tip3: "单个文件不超过100MB",
    add1: "添加目录",
    add2: "添加子目录",
    edit1: "修改目录",
    edit2: "修改文件",
    copy: "复制成功!",
    nameRule1: "名称不能为空",
    directory: "文件夹"
  },
  personal: {
    profile: "基本信息",
    edit1: "修改用户名",
    oldUsername: "旧用户名",
    newUsername: "新用户名",
    tip1: "每位用户仅可修改一次!",
    edit2: "修改密码",
    oldPassword: "旧密码",
    newPassword: "新密码",
    edit3: "修改头像",
    aboutMe: "关于我"
  },
  home: {
    replyToMe: "回复我的",
    commentMyArticle: "对我的文章发表了评论",
    reply: "回复",
    liked: "已赞",
    like: "点赞",
    deleteNotice: "删除该通知",
    mine: "@ 我的",
    me: "@了我",
    ownLike: "收到的赞",
    likedArticle: "赞了我的文章",
    likedComment: "赞了我的评论",
    privateMessage: "私信",
    noMoreNotice: "不再通知",
    systemNotice: "系统通知",
    myMessage: "我的消息",
    setting: "消息设置",
    sendMessage: "发送消息",
    recipient: "收件人",
    selectRecipient: "请选择收件人",
    title: "标题",
    inputTitle: "请输入标题",
    content: "内容",
    inputContent: "请输入内容",
    send: "发送",
    emailNotice: "邮件通知",
    theme: "主题",
    inputTheme: "请输入主题",
    recipientRule1: "收件人不能为空!",
    titleRule1: "标题不能为空!",
    themeRule1: "主题不能为空!",
    contentRule1: "内容不能为空!"
  }
};
